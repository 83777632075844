import { Button, Form, Input, Row, Col, Select } from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import LoadingIcon from "../../../Loader/LoadingIcon";
import { API_URL } from "../../../../../config/ENV_CONSTS";
import { filterLabelOptions } from "../../../../../utils/filterLabelOptions";
import { TAG_MODULES } from "../../settings/utils/tag_modules";

export const EditTag = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const {
    rightDrawerConfig,
    setRightDrawerConfig,
    instance,
    etiquetas,
    setEtiquetas,
    messageApi,
  } = useContext(GlobalContext);

  const actualizarLista = (item, v) => {
    let updatedTag = {
      _id: item._id,
      name: v.name,
      description: v.description,
      color: v.color,
      instanceId: item.instanceId,
      module: v.module
    };

    let updatedTags = etiquetas?.map((tag) => {
      if (tag._id === item._id) {
        return updatedTag;
      } else {
        return tag;
      }
    });

    setEtiquetas(updatedTags);
    setRightDrawerConfig({ visible: false, content: "" });
  };

  const editEtiqueta = (v) => {
    if (instance?._id) {
      let data = {
        _id: rightDrawerConfig.item._id,
        name: v.name.toUpperCase(),
        description: v.description,
        color: v.color,
        instanceId: rightDrawerConfig.item.instanceId,
        module: v.module
      };

      let config = {
        method: "put",
        url: `${API_URL}/tags/updateTag`,
        data: data,
        headers: {
          Authorization: `${instance?.userData?.apiKey}`,
          "Content-Type": "application/json",
        },
      };

      setLoading(true);

      axios(config)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success("Etiqueta editada correctamente");
            actualizarLista(rightDrawerConfig.item, v);
          }
        })
        .catch((error) => {
          console.log(error);
          messageApi.error("Ocurrió un error al editar una etiqueta");
        })
        .finally(() => setLoading(false));
    } else {
      messageApi.error("Ocurrió un error al editar una etiqueta");
    }
  };

  const onFailed = (v) => { };

  return (
    <div>
      <p style={{ marginBottom: 16, fontWeight: 600 }}>Editar etiqueta</p>
      <Form
        form={form}
        requiredMark="optional"
        name="newApplication"
        layout="vertical"
        onFinish={(v) => editEtiqueta(v)}
        onFinishFailed={(v) => onFailed(v)}
        style={{ marginTop: "16px" }}
        className="form-nodos"
      >
        <Row style={{ justifyContent: "space-between" }}>
          <Col span={16}>
            <Form.Item
              label="Nombre"
              name="name"
              initialValue={rightDrawerConfig.item.name}
              rules={[
                {
                  required: true,
                  message: "Campo requerido",
                },
              ]}
            >
              <Input size="large" maxLength={15} showCount />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Color"
              name="color"
              initialValue={rightDrawerConfig.item.color}
              rules={[
                {
                  required: true,
                  message: "Campo requerido",
                },
              ]}
            >
              <Input type="color" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Módulo"
          name="module"
          initialValue={rightDrawerConfig.item.module}
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Select
            options={TAG_MODULES}
            showSearch
            filterOption={filterLabelOptions}
          />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          initialValue={rightDrawerConfig.item.description}
          rules={[
            {
              required: true,
              message: "Campo requerido",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item className="form-custom-footer">
          <div className="botones-wrapper-content">
            <Button
              type="primary"
              htmlType="submit"
              className="btn-guardar"
              size="large"
              //loading={loading}
              icon={
                loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              type="secondary"
              onClick={() =>
                setRightDrawerConfig({
                  visible: false,
                  content: "",
                })
              }
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
