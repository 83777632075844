// Modulos de etiquetas posibles, para categorizarlas:
export const TAG_MODULES = [
  {
    label: 'Contactos',
    value: 'CONTACTS',
  },
  {
    label: 'Chat',
    value: 'CHAT',
  },
];