import { associateContactToClients } from "../../../../helpers/fetchData/fetchContacts";

export const fetchAssociateClients = async (instance, data, messageApi) => {
  // Podría ser una funcion a llamar en los endpoints de create y update contacts. (Si se usara solo en esos casos)
  associateContactToClients(instance?.userData?.apiKey, data)
    .then()
    .catch((err) => {
      messageApi.error("Hubo un error al asociar el contacto con las cuentas");
      console.error(err);
    });
};
