import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";
import { createAPIService } from "../createAPIService";

export const clientsService = createAPIService("/clients");

export const executeExternalClientsLoad = async (key) => {
  if (key) {
    let config = {
      method: "get",
      url: `${API_URL}/clients/executeExternalClientsLoad`,
      headers: {
        Authorization: key,
      },
    };

    const res = axios(config);

    return await res;
  }
};

export const executeExternalClientsLoadExcel = async (key, data) => {
  if (key) {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/clients/executeExternalClientsLoadExcel`,
      headers: {
        Authorization: key,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  }
};

export const multipleClientsByIds = async (key, data) => {
  try {
    const config = {
      method: "post",
      url: `${API_URL}/clients/multipleClientsByIds`,
      headers: {
        Authorization: key,
      },
      data: { ids: data },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const getAssociatedClients = async (apiKey, id) => {
  const config = {
    method: "get",
    url: `${API_URL}/clients/getAssociatedClients/${id}`,
    headers: {
      Authorization: apiKey,
    },
  };

  const res = await axios(config);

  return res;
};

export const getClient = async (instance, id) => {
  try {
    let config = {
      method: "get",
      url: `${API_URL}/clients/getClient/${id}`,
      headers: {
        Authorization: `${instance.userData?.apiKey}`,
      },
    };
    const res = await axios(config);

    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getUsersAssociatedToClients = async (apiKey, id) => {
  const config = {
    method: "get",
    url: `${API_URL}/clients/getUsersAssociated/${id}`,
    headers: {
      Authorization: apiKey,
    },
  };

  const res = await axios(config);

  return res;
};
