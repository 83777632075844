import { contactsService } from "../../../../helpers/fetchData/fetchContacts";
import { fetchAssociateClients } from "./fetchAssociateClients";

export const handleSaveEdit = async (
  formattedValues,
  item,
  setContactos,
  selectedClients,
  handleClose,
  setLoading,
  instance,
  messageApi,
  mainClient
) => {
  // 1. Edita el contacto:

  if (mainClient) {
    setLoading(false);
    // Valido que el contacto principal sea una de las opciones seleccionadas
    if (!selectedClients?.includes(mainClient)) {
      return messageApi.info(
        "La cuenta principal no se encuentra dentro de la lista de cuentas seleccionadas"
      );
    }
  }

  contactsService
    .update(instance?.userData?.apiKey, item._id, formattedValues)
    .then((res) => {
      if (res?.status === 200) {
        messageApi.success("Contacto editado correctamente");

        // 2. Actualiza la tabla:
        setContactos((prevState) => {
          let array = prevState.map((contacto) => {
            if (contacto._id === item._id) {
              return {
                ...contacto,
                ...formattedValues,
                selectedClients,
                mainClient: mainClient || null,
              };
            }

            return contacto;
          });

          return array;
        });

        const associateClientsData = {
          contactId: item._id,
          clientsIds: selectedClients,
          mainClient: mainClient,
        };

        // 3. Asocia al contacto con los clientes indicados en el formulario:
        fetchAssociateClients(instance, associateClientsData, messageApi);
        handleClose();
      } else {
        messageApi.error("Ocurrió un error al editar el contacto");
      }
    })
    .catch((err) => {
      console.error(err);
      if (err?.response?.data?.message) {
        messageApi.error(err.response.data.message);
      } else {
        messageApi.error("Ocurrió un error al crear el contacto");
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
