import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const updateUserRole = async (apiKey, data) => {
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${API_URL}/instance/updateUserRole`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getUserData = async (apiKey, userId) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_URL}/users/getUserData/${userId}`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/users/updateUser`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getUserById = async (instance, id) => {
  try {
    const config = {
      method: "get",
      url: `${API_URL}/users/getUserById/${id}`,
      headers: {
        Authorization: instance?.userData?.apiKey,
      },
    };

    const res = await axios(config);

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const updateUserPicture = async (apiKey, picture) => {
  const config = {
    method: "put",
    url: `${API_URL}/users/updateUserPicture`,
    headers: { Authorization: apiKey },
    data: { picture },
  };

  const res = await axios(config);

  return res;
};

export const getUsersConfig = async (apiKey) => {
  const config = {
    method: "get",
    url: `${API_URL}/users/usersConfig`,
    headers: { Authorization: apiKey },
  };

  const res = await axios(config);

  return res;
};

export const upsertUsersConfig = async (apiKey, data) => {
  const config = {
    method: "post",
    url: `${API_URL}/users/usersConfig`,
    headers: { Authorization: apiKey },
    data: data,
  };

  const res = await axios(config);

  return res;
};
