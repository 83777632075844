import { useContext, useEffect, useState, useMemo } from "react";
import { Form, Select, Divider } from "antd";
import LoadingIcon from "../../Loader/LoadingIcon";
import { GlobalContext } from "../../../context/GlobalContext";
import { getAssociatedClients } from "../../../../helpers/fetchData/fetchClients";
import { SelectCuentas } from "../../select-cuentas/SelectCuentas";
import { BoxInfo } from "../../box-info/BoxInfo";
import { filterLabelOptions } from "../../../../utils/filterLabelOptions";

export const AssociateClients = ({
  editMode,
  form,
  selectedClients,
  setSelectedClients,
  item,
  mainClient,
  setMainClient,
}) => {
  const { instance, messageApi, cuentas } = useContext(GlobalContext);

  const [initialClients, setInitialClients] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);

  useEffect(() => {
    if (instance?.userData?.apiKey && item && form && editMode) {
      setLoadingClientes(true);
      getAssociatedClients(instance.userData.apiKey, item._id)
        .then((res) => {
          if (res?.status === 200) {
            setInitialClients(res.data || []);

            const ids = res.data?.map((c) => c._id);
            setSelectedClients(ids);
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error(
            "No se pudo obtener las cuentas asociados al contacto"
          );
        })
        .finally(() => setLoadingClientes(false));
    }
  }, [instance, item, form, editMode]);

  const mainClientOptions = useMemo(() => {
    let array = cuentas?.filter((element) =>
      selectedClients.includes(element._id)
    );

    return array.map((element) => {
      return {
        label: `${element.idSistema} - ${element.name}`,
        value: element._id,
      };
    });
  }, [selectedClients, cuentas]);

  useEffect(() => {
    if (item?.mainClient) {
      if (
        mainClientOptions
          .map((contacto) => contacto.value)
          .includes(item.mainClient)
      ) {
        setMainClient(item.mainClient);
      }
    }
  }, [item, mainClientOptions]);

  useEffect(() => {
    if (!selectedClients?.includes(mainClient)) {
      setMainClient(null);
    }
  }, [mainClient, selectedClients]);

  return (
    <Form
      className="form-nodos"
      form={form}
      name="selectClients"
      layout="vertical"
    >
      {loadingClientes ? (
        <span
          style={{
            height: 300,
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <LoadingIcon size="large" />
        </span>
      ) : (
        <>
          <Form.Item
          // name='clients'
          >
            <p style={{ marginBottom: 8 }}>Cuentas</p>
            <SelectCuentas
              value={selectedClients}
              setValue={setSelectedClients}
              mode="multiple"
              existingClients={initialClients}
            />
          </Form.Item>

          {!loadingClientes && selectedClients?.length > 0 && (
            <>
              <span className="horizontal" style={{ marginBottom: 8 }}>
                <p>Cuenta principal</p>
                <BoxInfo
                  hideMode={true}
                  message="Será la cuenta de referencia para usarlo en bots, tickets, chats, etc."
                  maxWidth={400}
                />
              </span>
              <Select
                options={mainClientOptions || []}
                value={mainClient}
                onChange={(v) => setMainClient(v)}
                placeholder="Seleccione cuenta"
                loading={loadingClientes}
                showSearch
                filterOption={filterLabelOptions}
              />
            </>
          )}
        </>
      )}
      <Divider />
    </Form>
  );
};
