import { validateOneForm } from "./validateOneForm";

export const validateForms = async (forms) => {
  let isValid = true;
  let errorFields = [];
  let values = {};

  // Valida cada uno de los formularios por separado. Si alguno no es válido, impide que se añada/edite el contacto y devuelve los errores:
  await Promise.all([
    validateOneForm(forms[0], "Información General"),
    validateOneForm(forms[1], "Etiquetas"),
    validateOneForm(forms[2], "Campos adicionales"),
    validateOneForm(forms[3], "Cuentas"),
  ]).then((results) => {
    results.forEach((result) => {
      if (!result.isValid) {
        isValid = false;
        errorFields.push({
          formName: result.formName,
          errors: result.errorFields,
        });
      }
      values = { ...values, ...result.values };
    });
  });

  return {
    isValid,
    errorFields,
    values,
  };
};
