import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { Button, Drawer, Form, Tabs, Tooltip } from "antd";
import LoadingIcon from "../Loader/LoadingIcon";
import { AiOutlineUser } from "react-icons/ai";
import { FiTag, FiUsers } from "react-icons/fi";
import { GeneralInfo } from "./contactEditor/GeneralInfo";
import { TagContact } from "./contactEditor/TagContact";
import { AssociateClients } from "./contactEditor/AssociateClients";
import { ContactAdditionalFields } from "./contactEditor/ContactAdditionalFields";
import { RiPlayListAddLine } from "react-icons/ri";
import { FormErrorsNotification } from "./contactEditor/FormErrorsNotification";
import { formatContactData } from "./utils/formatContactData";
import { getAdditionalFields } from "../../../helpers/fetchData/fetchAdditionalFields";
import { validateForms } from "./utils/validateForms";
import { handleSaveAdd } from "./utils/handleSaveAdd";
import { handleSaveEdit } from "./utils/handleSaveEdit";

export const ContactEditor = ({
  isQuickAction,
  editMode,
  setLeads,
  isOpenDrawer,
  setIsOpenDrawer,
  item,
  leadMode,
  padding = 0,
}) => {
  const {
    notificationApi,
    instance,
    messageApi,
    setContactos,
    additionalFields,
    setAdditionalFields,
    loadingAdditionalFields,
    setLoadingAdditionalFields,
  } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);

  const [selectedClients, setSelectedClients] = useState([]);

  const [mainClient, setMainClient] = useState(null);

  const [editContactForm] = Form.useForm();
  const [editGeneralInfoForm] = Form.useForm();
  const [contactTagsForm] = Form.useForm();
  const [editAdditionalFieldsForm] = Form.useForm();
  const [selectClientsForm] = Form.useForm();

  // Cargar todos los campos adicionales:
  useEffect(() => {
    if (instance?.userData?.apiKey && isQuickAction) {
      getAdditionalFields(
        instance,
        messageApi,
        setAdditionalFields,
        setLoadingAdditionalFields
      );
    }
  }, [instance]);

  // Enviar formulario:
  const handleSave = async () => {
    const { isValid, errorFields, values } = await validateForms([
      editGeneralInfoForm,
      contactTagsForm,
      editAdditionalFieldsForm,
      selectClientsForm,
    ]);

    // Si alguno de los formularios no es válido, impide que se añada/edite el contacto y devuelve una notificación con los errores:
    if (!isValid) {
      return notificationApi.error({
        placement: "top",
        message: "Formulario no válido",
        description: <FormErrorsNotification errorFields={errorFields} />,
        duration: 10,
      });
    }

    let formattedValues = formatContactData(values, additionalFields);

    setLoading(true);
    // EDITAR CONTACTO:
    if (editMode) {
      await handleSaveEdit(
        formattedValues,
        item,
        setContactos,
        selectedClients,
        handleClose,
        setLoading,
        instance,
        messageApi,
        mainClient
      );
    } else {
      // AÑADIR CONTACTO:
      await handleSaveAdd(
        formattedValues,
        setContactos,
        selectedClients,
        item,
        setLeads,
        setLoading,
        handleClose,
        leadMode,
        instance,
        messageApi,
        mainClient
      );
    }
  };

  const tabsItems = [
    {
      key: "generalInfo",
      icon: (
        <Tooltip title="Información general">
          <span>
            <AiOutlineUser size={20} />
          </span>
        </Tooltip>
      ),
      children: (
        <GeneralInfo
          editMode={editMode}
          leadMode={leadMode}
          form={editGeneralInfoForm}
          item={item}
        />
      ),
      forceRender: true,
    },
    {
      key: "additionalFields",
      icon: (
        <Tooltip title="Campos adicionales">
          <span>
            <RiPlayListAddLine size={20} />
          </span>
        </Tooltip>
      ),
      children: (
        <ContactAdditionalFields
          editMode={editMode}
          form={editAdditionalFieldsForm}
          loading={loadingAdditionalFields}
          item={item}
        />
      ),
      forceRender: true,
    },
    {
      key: "tags",
      icon: (
        <Tooltip title="Etiquetas">
          <span>
            <FiTag size={20} />
          </span>
        </Tooltip>
      ),
      children: (
        <TagContact editMode={editMode} form={contactTagsForm} item={item} />
      ),
      forceRender: true,
    },
    {
      key: "clients",
      icon: (
        <Tooltip title="Relaciones">
          <span>
            <FiUsers size={20} />
          </span>
        </Tooltip>
      ),
      children: (
        <AssociateClients
          editMode={editMode}
          form={selectClientsForm}
          selectedClients={selectedClients}
          setSelectedClients={setSelectedClients}
          item={item}
          mainClient={mainClient}
          setMainClient={setMainClient}
        />
      ),
      forceRender: true,
    },
  ];

  const handleClose = () => {
    setLoading(false);
    setIsOpenDrawer(false);
  };

  return (
    <Drawer
      width={500}
      destroyOnClose
      maskClosable={true}
      placement="right"
      closable={true}
      closeIcon={null}
      onClose={() => setIsOpenDrawer(false)}
      open={isOpenDrawer}
      styles={{ header: { display: "none" }, body: { padding: padding } }}
    >
      <Form
        form={editContactForm}
        name="editContact"
        className="form-nodos"
        style={{ overflow: "hidden", padding: !editMode ? 24 : 0 }}
        layout="vertical"
      >
        <p style={{ marginBottom: 16, fontWeight: 600 }}>
          {editMode ? "Editar" : "Añadir"} contacto
        </p>
        <Tabs items={tabsItems} centered />

        <Form.Item className="form-custom-footer form-contact-footer">
          <div className="botones-wrapper-content">
            <Button
              type="primary"
              className="btn-guardar"
              size="large"
              icon={
                loading ? <LoadingIcon size="small" color="#FFFFFF" /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
              onClick={handleSave}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              type="secondary"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
