import axios from "axios";
import { META_API_VERSION } from "./fetchApplication";
import { API_URL, WEBHOOK_URL } from "../../config/ENV_CONSTS";

export const getChats = async (apiKey, page, search) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/getRecentChats`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      params: {
        page,
        limit: 25,
        ...(search ? { search } : {}),
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const markMessageAsRead = async (messageId, application) => {
  try {
    const data = {
      messaging_product: "whatsapp",
      status: "read",
      message_id: messageId,
    };

    const config = {
      method: "post",
      url: `https://graph.facebook.com/${META_API_VERSION}/${application?.[0]?.lines?.[0]?.phoneNumberId}/messages`,
      headers: {
        Authorization: `Bearer ${application?.[0]?.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newChat = async (apiKey, data, myTeams, messageApi) => {
  try {
    if (!myTeams || myTeams?.length === 0) {
      messageApi.info(
        "Agente sin equipo. No podés iniciar un ticket sin pertenecer a un equipo"
      );

      throw new Error("El agente no tiene equipo");
    } else {
      if (apiKey && data) {
        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${API_URL}/chat/newChat`,
          headers: {
            Authorization: apiKey,
            "Content-Type": "application/json",
          },
          data: data,
        };

        const res = axios.request(config);

        return await res;
      }
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getConversationHistory = async (apiKey, phoneNumber) => {
  try {
    if (apiKey && phoneNumber) {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${API_URL}/chat/historicChat/${phoneNumber}`,
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
      };

      const res = axios.request(config);

      return await res;
    }
  } catch (error) {
    console.log(error);
  }
};

export const closeChat = async (apiKey, data) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/closeChat`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newChatNote = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/notes/newNote`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getConversation = async (apiKey, phoneNumber) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/getConversation/${phoneNumber}`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getTicketConversation = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/getTicketConversation`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const newAttachmentChat = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/attachments/newAttachment`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const sendTemplateInChat = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/sendTemplateInChat`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const takeNotAssigned = async (apiKey, data) => {
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/takeNotAssigned`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const expirationTime = async (apiKey, phoneNumber) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/getExpirationTime`,
      headers: {
        Authorization: apiKey,
      },
      data: { phoneNumber: phoneNumber },
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAvailableAgents = (apiKey) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/agent/available`,
      headers: {
        Authorization: apiKey,
      },
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserAssigned = async (apiKey, data) => {
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/assignChat`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserTeam = async (apiKey, data) => {
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/changeUserTeam`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const enableDisableCloudStorage = (apiKey, value) => {
  try {
    const config = {
      method: "post",
      url: `${API_URL}/chat/enableDisableCloudStorage`,
      headers: {
        Authorization: apiKey,
      },
      data: value,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const transferToBot = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${WEBHOOK_URL}/auth/callFromChat`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getContactsStatus = async (apiKey, page, search) => {
  try {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_PRODUCCION}/chat/contactsStatus`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      params: {
        page,
        limit: 25,
        ...(search ? { search } : {}),
      },
    };

    const res = axios.request(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateClientAssigned = async (apiKey, data) => {
  try {
    const config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/assignClient`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createTicket = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/createTicket`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const speechToText = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/speechToText`,
      headers: {
        Authorization: apiKey,
      },
      data: data,
    };

    const res = axios.request(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getChat = async (apiKey, id) => {
  try {
    let config = {
      method: "get",
      url: `${API_URL}/chat/getChat/${id}`,
      headers: {
        Authorization: apiKey,
      },
    };
    const res = await axios(config);

    return res;
  } catch (err) {
    console.log(err);
  }
};