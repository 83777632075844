import axios from "axios";
import { API_URL } from "../../config/ENV_CONSTS";

export const fetchInstance = async (
  user,
  messageApi,
  setLoadingInstance,
  setWorkspaces,
  token,
  setInstance,
  logout
) => {
  try {
    if (user) {
      setLoadingInstance(true);

      getInstance(token)
        .then((res) => {
          if (res?.status === 200) {
            const { data, user } = res.data || {};

            let array = data?.instance
              ?.map((workspace) => {
                let association = user?.associations?.find(
                  (element) => element.instanceId === workspace._id
                );

                return {
                  ...workspace,
                  userData: { userId: user._id, ...association },
                };
              })
              ?.filter((workspace) => {
                if (workspace.userData?.status === false) {
                  return false;
                }

                return true;
              });

            if (!data?.invitations?.length && !array?.length && !data?.draft) {
              setInstance({ create: true });
            }

            setWorkspaces({ ...data, instances: array });
          } else {
            messageApi.error("Error al obtener workspaces");
          }
        })
        .catch((error) => {
          if (["", "/", "/main"].includes(window.location.pathname)) {
            messageApi.error(
              error?.response?.data?.message || "Error al obtener workspaces"
            );

            if ([401, 403].includes(error?.response?.status)) {
              logout({ returnTo: window.location.origin });
            } else {
              window.location.replace("/home");
            }
          }
        })
        .finally(() => {
          setLoadingInstance(false);
        });
    }
  } catch (error) {
    console.log("error", error);
    messageApi.error("Error al obtener workspace");
    setLoadingInstance(false);
  }
};

export const getInstance = async (token) => {
  let config = {
    method: "get",
    url: `${API_URL}/instance/getInstance`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const res = axios(config);

  return await res;
};

export const uploadProfilePicture = async (key, data) => {
  try {
    let config = {
      method: "get",
      url: `${API_URL}/uploadProfilePicture`,
      headers: {
        "Content-Type": "application/json",
        Authorization: key,
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserStatus = async (data, apiKey) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/chat/agent/agentStatus`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const lastLoginUpdate = async (apiKey) => {
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${API_URL}/users/login`,
    headers: {
      Authorization: apiKey,
      "Content-Type": "application/json",
    },
  };

  const res = await axios(config);

  return res;
};

export const updateUserAccess = async (apiKey, data) => {
  try {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${API_URL}/instance/userAccess`,
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const validateInstanceName = async (nombre) => {
  try {
    // let data = JSON.stringify({
    //   name: nombre,
    // });

    // nombre =

    const data = { name: nombre };

    let config = {
      method: "post",
      url: `${API_URL}/instance/validateName`,
      headers: {
        Authorization: "asdasdas",
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const confirmDraft = async (data) => {
  try {
    let config = {
      method: "post",
      url: `${API_URL}/instance/confirmDraft`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const createDraftWorkspace = async (data) => {
  try {
    let config = {
      method: "post",
      url: `${API_URL}/instance/draftWorkspace`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};
