import { Button, Checkbox, Drawer, Form, InputNumber, Select } from 'antd';
import LoadingIcon from '../../Loader/LoadingIcon';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { configCampaign } from '../../../../helpers/fetchData/fetchCampaigns';
import { BoxInfoPopUp } from '../../box-info/BoxInfoPopUp';
import { CampaignsContext } from '../../../context/CampaignsContext';

export const CampaignSettingsDrawer = ({ isOpen, data }) => {
  const { instance, messageApi } = useContext(GlobalContext);
  const { closeSettingsDrawer } = useContext(CampaignsContext);

  const [loading, setLoading] = useState(false);
  const [isIncomeActive, setIsIncomeActive] = useState(false);

  const [settingsForm] = Form.useForm();

  useEffect(() => {
    if (data?.initialValues?.isIncomeActive !== undefined) {
      setIsIncomeActive(data.initialValues.isIncomeActive);
    }
  }, [data]);

  const handleFinish = (values) => {
    const dataToSend = {
      id: data._id,
      isIncomeActive,
      income: isIncomeActive && values.income ? values.income : 0,
      conversionActions: values.conversionActions || [],
      dropoutActions: values.dropoutActions || []
    };

    if (instance?.userData?.apiKey) {
      setLoading(true);
      configCampaign(instance, dataToSend)
        .then((res) => {
          if (res?.status === 200) {
            messageApi.success('Campaña configurada');
            data.updateCampaignData();
            closeSettingsDrawer(settingsForm);
          }
        })
        .catch((err) => {
          console.error(err);
          messageApi.error('No se pudo configurar la campaña');
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Drawer
      width={500}
      destroyOnClose
      maskClosable={true}
      placement='right'
      closable={true}
      closeIcon={null}
      onClose={() => closeSettingsDrawer(settingsForm)}
      open={isOpen}
      // styles={{ header: { display: 'none' }, body: { padding: padding } }}
    >
      <Form
        form={settingsForm}
        name='campaignSettings'
        className='form-nodos'
        // style={{ overflow: 'hidden', padding: !editMode ? 24 : 0 }}
        layout='vertical'
        onFinish={handleFinish}
        clearOnDestroy={true}
      >
        <p style={{ marginBottom: 16, fontWeight: 600 }}>
          Configuración de campaña
        </p>

        <Form.Item
          label={
            <div className='campaign-data-settings-drawer-input-label'>
              <span>Ingresos generados</span>
              <BoxInfoPopUp
                message='Ingresos totales en dólares generados a partir de la campaña'
                hideMode
                maxWidth={250}
              />
            </div>
          }
          name='income'
          initialValue={data?.initialValues?.income || 0}
        >
          <InputNumber addonBefore='USD' min={0} controls={false} />
        </Form.Item>
        <Checkbox
          style={{ marginBottom: 20 }}
          onChange={(v) => setIsIncomeActive(v.target?.checked)}
          checked={isIncomeActive}
        >
          <div className='campaign-data-settings-drawer-input-label'>
            <span>Habilitar ingresos generados</span>
            <BoxInfoPopUp
              message='Si está habilitado, podrá registrar los ingresos totales en dólares generados por la campaña. Además, esto permitirá calcular el RPR, RoCS y ROI'
              hideMode
              maxWidth={250}
            />
          </div>
        </Checkbox>

        <Form.Item
          label={
            <div className='campaign-data-settings-drawer-input-label'>
              <span>Conversion</span>
              <BoxInfoPopUp
                message='Acciones deseadas'
                hideMode
                maxWidth={250}
              />
            </div>
          }
          name='conversionActions'
          initialValue={data?.initialValues?.conversionActions}
        >
          <Select mode='multiple' options={data?.actionsOptions} />
        </Form.Item>

        <Form.Item
          label={
            <div className='campaign-data-settings-drawer-input-label'>
              <span>Dropout</span>
              <BoxInfoPopUp
                message='Acciones no deseadas'
                hideMode
                maxWidth={250}
              />
            </div>
          }
          name='dropoutActions'
          initialValue={data?.initialValues?.dropoutActions}
        >
          <Select mode='multiple' options={data?.actionsOptions} />
        </Form.Item>

        <Form.Item className='form-custom-footer'>
          <div className='botones-wrapper-content'>
            <Button
              htmlType='submit'
              type='primary'
              className='btn-guardar'
              size='large'
              icon={
                loading ? <LoadingIcon size='small' color='#FFFFFF' /> : null
              }
              style={{ opacity: loading ? 0.65 : 1 }}
            >
              Guardar
            </Button>
            <Button
              className='btn-cancelar'
              size='large'
              type='secondary'
              onClick={() => closeSettingsDrawer(settingsForm)}
            >
              Cerrar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
