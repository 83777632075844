import { useContext } from "react";
import { FiPlus } from "react-icons/fi";
import { GlobalContext } from "../../../context/GlobalContext";
import ModuleMenu from "./ModuleMenu";
import { Image, Tooltip } from "antd";
import { NewHandlerModal } from "../sideDrawer/contentDrawer/plusContent/NewHandlerModal";

const TopMenu = () => {
  const { activeMenuItem, setActiveMenuItem, instance } =
    useContext(GlobalContext);

  function handleClickNewElement() {
    setActiveMenuItem(true);
  }

  return (
    <div className="sidebar-top">
      {instance?.profilePicture_url && (
        <Tooltip title={instance?.orgName} placement="right">
          <div
            className="user-menu"
            style={{
              marginTop: 16,
              width: 42,
              height: 42,
              borderRadius: "3px",
            }}
          >
            <Image
              src={instance.profilePicture_url}
              preview={false}
              className="user-menu-image-workspace"
              style={{ padding: "1px" }}
              referrerPolicy={"no-referrer"}
            />
          </div>
        </Tooltip>
      )}
      <div className="menu-tools">
        <Tooltip placement="right" title="Nuevo" mouseEnterDelay={0.3}>
          <div
            className={activeMenuItem ? "item-menu-selected" : "item-menu"}
            onClick={handleClickNewElement}
          >
            <FiPlus
              className={activeMenuItem ? "icon-menu-selected" : "icon-menu"}
            />
          </div>
        </Tooltip>
      </div>

      <ModuleMenu />

      <NewHandlerModal open={activeMenuItem} setOpen={setActiveMenuItem} />
    </div>
  );
};

export default TopMenu;
