import { lazy, Suspense, useContext, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { accessDataFormat } from "../../helpers/access";
import { useIsDevsInstance } from "../../hooks/useIsDevsInstance";
import { BrocolyAdminRoute } from "./BrocolyAdminRoute";
import { GlobalContext } from "../context/GlobalContext";
import { CampaignsProvider } from "../context/CampaignsContext";
import MainLayout from "../ui/Layout/MainLayout";
import ConfigView from "../views/ConfigView";
import { useSocketNotifications } from "../../hooks/useSocketNotifications";

const DashboardView = lazy(() =>
  lazyRetry(() => import("../views/DashboardView"))
);
const ContactoView = lazy(() =>
  lazyRetry(() => import("../views/ContactoView"))
);

const FlowsView = lazy(() => lazyRetry(() => import("../views/FlowsView")));
const EditFlowView = lazy(() =>
  lazyRetry(() => import("../views/EditFlowView"))
);
const ChatView = lazy(() => lazyRetry(() => import("../views/ChatView")));
// const ConfigView = lazy(() => import("../views/ConfigView"));
const Templates = lazy(() =>
  lazyRetry(() => import("../ui/Layout/settings/content/templates/Templates"))
);
const ProductsAndServices = lazy(() =>
  lazyRetry(() =>
    import(
      "../ui/Layout/settings/content/productsAndServices/ProductsAndServices"
    )
  )
);
const Tags = lazy(() =>
  lazyRetry(() => import("../ui/Layout/settings/content/Tags"))
);
const Users = lazy(() =>
  lazyRetry(() => import("../ui/Layout/settings/content/users/Users"))
);
const CanalesContent = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/settings/content/channels/CanalesContent")
  )
);
const Teams = lazy(() =>
  lazyRetry(() => import("../ui/Layout/settings/content/teams/Teams"))
);
const Integrations = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/settings/content/integrations/Integrations")
  )
);
const PresupuestoView = lazy(() =>
  lazyRetry(() => import("../views/PresupuestoView"))
);
const ContactsSettings = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/settings/content/contacts/ContactsSettings")
  )
);
const InitialView = lazy(() => lazyRetry(() => import("../views/InitialView")));
const CampaignBuilderView = lazy(() =>
  lazyRetry(() => import("../views/CampaignBuilderView"))
);
const CampaignsView = lazy(() =>
  lazyRetry(() => import("../views/CampaignsView"))
);
const MostrarInstancia = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/settings/content/instance/MostrarInstancia")
  )
);
const TablaClientesView = lazy(() =>
  lazyRetry(() => import("../views/TablaClientesView"))
);
const ChatConfig = lazy(() =>
  lazyRetry(() => import("../ui/Layout/settings/content/chat/ChatConfig"))
);
const EnvioBuilderView = lazy(() =>
  lazyRetry(() => import("../views/EnvioBuilderView"))
);
const EnvioDataView = lazy(() =>
  lazyRetry(() => import("../views/EnvioDataView"))
);
const Bots = lazy(() =>
  lazyRetry(() => import("../ui/Layout/settings/content/bots/Bots"))
);
const CampaignsDataView = lazy(() =>
  lazyRetry(() => import("../views/CampaignsDataView"))
);
const ClientsConfig = lazy(() =>
  lazyRetry(() => import("../ui/Layout/settings/content/clients/ClientsConfig"))
);
const BrocolyAdminView = lazy(() =>
  lazyRetry(() => import("../views/BrocolyAdminView"))
);
const StorageSettings = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/settings/content/storage/StorageSettings")
  )
);
const Workspaces = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/brocoly-admin/content/workspaces/Workspaces")
  )
);
const Solicitudes = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/brocoly-admin/content/solicitudes/Solicitudes")
  )
);

const Demos = lazy(() =>
  lazyRetry(() => import("../ui/Layout/brocoly-admin/content/demos/Demos"))
);

const EnviosLogsView = lazy(() =>
  lazyRetry(() => import("../views/EnviosLogsView"))
);
const LandingView = lazy(() => lazyRetry(() => import("../views/LandingView")));
const CampaignsLogsView = lazy(() =>
  lazyRetry(() => import("../views/CampaignsLogsView"))
);
const TicketsView = lazy(() => lazyRetry(() => import("../views/TicketsView")));
const FAQView = lazy(() => lazyRetry(() => import("../views/FAQView")));
const LandingContactoView = lazy(() =>
  lazyRetry(() => import("../views/LandingContactoView"))
);
const TicketsSettings = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/settings/content/tickets/TicketsSettings")
  )
);
const PrivacyPolicy = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/landing-page/terms-and-privacy/PrivacyPolicy")
  )
);
const TermsOfUse = lazy(() =>
  lazyRetry(() =>
    import("../ui/Layout/landing-page/terms-and-privacy/TermsOfUse")
  )
);

const BotsDataView = lazy(() =>
  lazyRetry(() => import("../views/BotsDataView"))
);

const publicRoutes = [
  "/",
  "/home",
  "/faq",
  "/contacto",
  "/main",
  "/privacy-policy",
  "/terms-of-use",
];

const AppRouter = () => {
  const { instance } = useContext(GlobalContext);

  useSocketNotifications();

  const { isDevsInstance } = useIsDevsInstance();

  const location = useLocation();

  const fallbackContent = (pathname) => {
    if (publicRoutes.includes(pathname)) {
      return <div className="landing-page-wrapper"></div>;
    }

    if (pathname.includes("/settings")) {
      return <ConfigView></ConfigView>;
    }

    return <MainLayout />;
  };

  useEffect(() => {
    // Envio a google analitycs
    const path = location.pathname;

    // Check if current path is a public route
    const isPublicRoute =
      publicRoutes.includes(path) ||
      publicRoutes.some((route) => path.startsWith(route + "/"));

    // Only track if it's a public route
    if (isPublicRoute && typeof window.gtag === "function") {
      window.gtag("config", process.env.REACT_APP_GA_ID, {
        page_path: path + location.search,
      });
    }
  }, [location]);

  return (
    <>
      <Suspense fallback={fallbackContent(window.location.pathname)}>
        <Routes>
          <Route index element={<InitialView />} />
          <Route path="/home" element={<LandingView />} />
          <Route path="/faq" element={<FAQView />} />

          <Route path="/contacto" element={<LandingContactoView />} />
          {/* Revisar si debe ser privateRoute */}
          <Route path="/main" element={<InitialView />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route
            path="/mensajes/*"
            element={
              <CampaignsProvider>
                <Routes>
                  <Route
                    path="/envios/new"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat(
                          "broadcast",
                          "manage",
                          "/mensajes"
                        )}
                      >
                        <EnvioBuilderView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/envios/details"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat(
                          "broadcast",
                          "manage",
                          "/mensajes"
                        )}
                      >
                        <EnvioBuilderView edit={true} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/envios/logs"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat(
                          "broadcast",
                          "manage",
                          "/mensajes"
                        )}
                      >
                        <EnviosLogsView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/envios/data"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat(
                          "broadcast",
                          "show",
                          "/mensajes"
                        )}
                      >
                        <EnvioDataView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat("broadcast", "show")}
                      >
                        <CampaignsView initial tab="campaigns" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/envios"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat("broadcast", "show")}
                      >
                        <CampaignsView initial tab="envios" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/campaigns"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat("broadcast", "show")}
                      >
                        <CampaignsView initial tab="campaigns" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/campaigns/new"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat(
                          "broadcast",
                          "manage",
                          "/mensajes"
                        )}
                      >
                        <CampaignBuilderView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/campaigns/details"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat(
                          "broadcast",
                          "show",
                          "/mensajes"
                        )}
                      >
                        <CampaignBuilderView edit={true} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/campaigns/data"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat(
                          "broadcast",
                          "show",
                          "/mensajes"
                        )}
                      >
                        <CampaignsDataView />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/campaigns/logs"
                    element={
                      <PrivateRoute
                        accessData={accessDataFormat(
                          "broadcast",
                          "manage",
                          "/mensajes"
                        )}
                      >
                        <CampaignsLogsView />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </CampaignsProvider>
            }
          />
          <Route
            path="/tickets"
            element={
              <PrivateRoute
                accessData={accessDataFormat("tickets", "show", "/tickets")}
              >
                <TicketsView />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/*"
            element={
              <PrivateRoute accessData={accessDataFormat("settings", "show")}>
                <ConfigView>
                  <MostrarInstancia />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/templates"
            element={
              <PrivateRoute
                accessData={accessDataFormat("templates", "show", "/settings")}
              >
                <ConfigView>
                  <Templates />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/bots"
            element={
              <PrivateRoute
                accessData={accessDataFormat("bots", "show", "/settings")}
              >
                <ConfigView>
                  <Bots />
                </ConfigView>
              </PrivateRoute>
            }
          />
          {/* <Route
          path="/settings/templates/create"
          element={
            <PrivateRoute
              accessData={accessDataFormat(
                "templates",
                "manage",
                "/settings/templates"
              )}
            >
              <ConfigView>
                <CreateTemplate />
              </ConfigView>
            </PrivateRoute>
          }
        /> */}
          {/* <Route
          path="/settings/templates/edit"
          element={
            <PrivateRoute
              accessData={accessDataFormat("templates", "manage", "/settings")}
            >
              <ConfigView>
                <EditTemplate edit={true} />
              </ConfigView>
            </PrivateRoute>
          }
        /> */}
          <Route
            path="/settings/teams"
            element={
              <PrivateRoute
                accessData={accessDataFormat("teams", "show", "/settings")}
              >
                <ConfigView>
                  <Teams />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/channels"
            element={
              <PrivateRoute
                accessData={accessDataFormat("channels", "show", "/settings")}
              >
                <ConfigView>
                  <CanalesContent />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/workspace"
            element={
              <PrivateRoute
                accessData={accessDataFormat("workspaces", "show", "/settings")}
              >
                <ConfigView>
                  <MostrarInstancia />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/contacts"
            element={
              <PrivateRoute
                accessData={accessDataFormat("contacts", "show", "/settings")} // Reemplazar "contacts" por uno nuevo
              >
                <ConfigView>
                  <ContactsSettings />
                </ConfigView>
              </PrivateRoute>
            }
          />
          {/* <Route
          path="/settings/error-messages"
          element={
            <PrivateRoute
              accessData={accessDataFormat(
                'errorMessages',
                'show',
                '/settings'
              )}
            >
              <ConfigView>
                <ErrorMessages />
              </ConfigView>
            </PrivateRoute>
          }
        /> */}
          <Route
            path="/settings/integraciones"
            element={
              <PrivateRoute
                accessData={accessDataFormat(
                  "integrations",
                  "show",
                  "/settings"
                )}
              >
                <ConfigView>
                  <Integrations />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/tags"
            element={
              <PrivateRoute
                accessData={accessDataFormat("tags", "show", "/settings")}
              >
                <ConfigView>
                  <Tags />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/users"
            element={
              <PrivateRoute
                accessData={accessDataFormat("users", "show", "/settings")}
              >
                <ConfigView>
                  <Users />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/clients"
            element={
              <PrivateRoute
                accessData={accessDataFormat("clients", "show", "/settings")}
              >
                <ConfigView>
                  <ClientsConfig />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/storage"
            element={
              <PrivateRoute
                accessData={accessDataFormat("storage", "show", "/settings")}
              >
                <ConfigView>
                  <StorageSettings />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/tickets"
            element={
              <PrivateRoute
                accessData={accessDataFormat("tickets", "show", "/settings")}
              >
                <ConfigView>
                  <TicketsSettings />
                </ConfigView>
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <DashboardView />
              </PrivateRoute>
            }
          />
          <Route
            path="/contactos"
            element={
              <PrivateRoute accessData={accessDataFormat("contacts", "show")}>
                <ContactoView initial tab="activos" />
              </PrivateRoute>
            }
          />
          <Route
            path="/contactos/leads"
            element={
              <PrivateRoute accessData={accessDataFormat("contacts", "show")}>
                <ContactoView tab="leads" />
              </PrivateRoute>
            }
          />
          <Route
            path="/contactos/activos"
            element={
              <PrivateRoute accessData={accessDataFormat("contacts", "show")}>
                <ContactoView tab="activos" />
              </PrivateRoute>
            }
          />
          <Route
            path="/bots"
            element={
              <PrivateRoute accessData={accessDataFormat("bots", "show")}>
                <FlowsView />
              </PrivateRoute>
            }
          />
          <Route
            path="/bots/new"
            element={
              <PrivateRoute
                accessData={accessDataFormat("bots", "manage", "/bots")}
              >
                <EditFlowView type="trigger" />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/bots/new/broadcast"
            element={
              <PrivateRoute
                accessData={accessDataFormat("bots", "manage", "/bots")}
              >
                <EditFlowView type="broadcast" />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/bots/edit"
            element={
              <PrivateRoute
                accessData={accessDataFormat("bots", "manage", "/bots")}
              >
                <EditFlowView edit={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/bots/data"
            element={
              <PrivateRoute
                accessData={accessDataFormat("bots", "manage", "/bots")}
              >
                <BotsDataView />
              </PrivateRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <PrivateRoute accessData={accessDataFormat("clients", "show")}>
                <TablaClientesView />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings/clients"
            element={
              <PrivateRoute
                accessData={accessDataFormat("clients", "show", "/settings")}
              >
                <ConfigView>
                  <ClientsConfig />
                </ConfigView>
              </PrivateRoute>
            }
          />

          {/* //* Brocoly Admin */}
          <Route
            path="/brocoly-admin/*"
            element={
              <PrivateRoute>
                <BrocolyAdminRoute>
                  <BrocolyAdminView>
                    <Workspaces />
                  </BrocolyAdminView>
                </BrocolyAdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/brocoly-admin/solicitudes"
            element={
              <PrivateRoute>
                <BrocolyAdminRoute>
                  <BrocolyAdminView>
                    <Solicitudes />
                  </BrocolyAdminView>
                </BrocolyAdminRoute>
              </PrivateRoute>
            }
          />

          <Route
            path="/brocoly-admin/demos"
            element={
              <PrivateRoute>
                <BrocolyAdminRoute>
                  <BrocolyAdminView>
                    <Demos />
                  </BrocolyAdminView>
                </BrocolyAdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <DashboardView />
              </PrivateRoute>
            }
          />

          {/* Devs */}

          {isDevsInstance && (
            <>
              <Route
                path="/presupuestos/*"
                element={
                  <PrivateRoute
                    accessData={accessDataFormat(
                      "products-and-services",
                      "show"
                    )}
                  >
                    <PresupuestoView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings/products-and-services"
                element={
                  <PrivateRoute
                    accessData={accessDataFormat(
                      "products-and-services",
                      "show",
                      "/settings"
                    )}
                  >
                    <ConfigView>
                      <ProductsAndServices />
                    </ConfigView>
                  </PrivateRoute>
                }
              />
            </>
          )}
          {/* IsDevs o es Lehmann */}
          {(isDevsInstance || instance?._id === "65ddfee7bcaf74678a62bb6d") && (
            <>
              <Route
                path="/chat"
                element={
                  <PrivateRoute accessData={accessDataFormat("chat", "show")}>
                    <ChatView />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings/chat"
                element={
                  <PrivateRoute
                    accessData={accessDataFormat(
                      "chatSettings",
                      "show",
                      "/settings"
                    )}
                  >
                    <ConfigView>
                      <ChatConfig />
                    </ConfigView>
                  </PrivateRoute>
                }
              />
            </>
          )}
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRouter;

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        console.error("Chunk load error:", error);
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
        );
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page and updates client chunks
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};
