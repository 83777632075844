import { Menu } from "antd";
import { useContext, useState } from "react";
import { settingsMenuItems } from "./utils/smallUtilities";
import { useNavigate } from "react-router-dom";
import { useAccess } from "../../../../hooks/useAccess";

import { useIsDevsInstance } from "../../../../hooks/useIsDevsInstance";
import { GlobalContext } from "../../../context/GlobalContext";

export const Settings = ({ children }) => {
  const { instance } = useContext(GlobalContext);
  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const [selectedKeys, setSelectedKeys] = useState(["sub1"]);

  const isAllowed = useAccess();

  const { isDevsInstance } = useIsDevsInstance();

  const navigate = useNavigate();

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleSelectedItem = (key) => {
    navigate(`/settings/${key}`);
    setSelectedKeys(key);
  };

  return (
    <div className="config-module-wrapper">
      <div className="config-main-menu">
        <p className="config-module-title">Configuración</p>
        <Menu
          mode="inline"
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          forceSubMenuRender={true}
          defaultOpenKeys={["sub1"]}
          onOpenChange={onOpenChange}
          style={{
            width: 200,
            fontSize: "13px",
          }}
          onClick={(v) => handleSelectedItem(v.key)}
          items={settingsMenuItems(isAllowed, isDevsInstance, instance)}
        />
      </div>
      <div className="config-content-wrapper">{children}</div>
    </div>
  );
};
