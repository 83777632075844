import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../components/context/GlobalContext";
import io from "socket.io-client";

export const useSocketNotifications = () => {
  const { instance, setInstance, workspaces, setWorkspaces } =
    useContext(GlobalContext);

  const socketRef = useRef(null);

  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    // Detailed socket configuration with extensive logging
    const socket = io(process.env.REACT_APP_API_SOCKET, {
      autoConnect: false,
      reconnection: true,
      reconnectionAttempts: Infinity, // Keep trying to reconnect
      reconnectionDelay: 1000, // Start with 1 second
      reconnectionDelayMax: 5000, // Maximum delay of 5 seconds
      randomizationFactor: 0.5,
      timeout: 30000,
      transports: ["websocket", "polling"], // Prefer websocket, fallback to polling
      forceNew: true, // Create a new connection each time
      rejectUnauthorized: false, // Disable SSL certificate validation (for development)
    });

    socketRef.current = socket;

    // Comprehensive logging function
    const logConnectionEvent = (eventName, details = {}) => {
      // console.log("Socket event", eventName);
      // console.log("details", details);
    };

    let retryAttempts = 0;
    const maxRetries = 5;

    // Connect event handlers
    const onConnect = () => {
      logConnectionEvent("connect", {
        socketId: socket.id,
        instanceName: instance?.name,
      });
      setIsConnected(true);
      retryAttempts = 0;
      //   messageApi.success("Socket Connected Successfully");
    };

    const onDisconnect = (reason) => {
      logConnectionEvent("disconnect", { reason });
      setIsConnected(false);

      if (instance?.userData?.apiKey) {
        const attemptReconnect = () => {
          if (retryAttempts < maxRetries) {
            const delay = Math.pow(2, retryAttempts) * 3000; // Exponential backoff (3s, 6s, 12s, 24s, 48s)

            console.log(
              `Attempting reconnection in ${delay / 1000} seconds...`
            );
            setTimeout(() => {
              socket.auth = {
                token: instance.userData.apiKey,
                instanceName: instance.name,
              };
              socket.connect();
              retryAttempts++;
            }, delay);
          } else {
            console.log("Max reconnection attempts reached.");
          }
        };

        attemptReconnect();
      }
    };

    const onConnectError = (error) => {
      logConnectionEvent("connection_error", {
        errorMessage: error.message,
        errorCode: error.code,
      });

      // Advanced error handling
      if (error.message.includes("Session ID unknown")) {
        // Force a new connection
        socket.disconnect();
        socket.connect();
      }
    };

    // Attach event listeners
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", onConnectError);

    // Notification listeners
    const handleApiNotifications = (notification) => {
      try {
        // console.log("🔔 API Notification:", notification);
        const { type, data } = JSON.parse(notification) || {};

        if (!type) {
          return;
        }

        switch (type) {
          case "role-update":
            handleRoleUpdate(data);
            break;
          case "user-role-update":
            handleUserRoleUpdate(data);
            break;

          default:
            console.log("TYPE NO SUPPORTED", type);
            break;
        }
      } catch (error) {
        console.log("api notification", error);
      }
    };

    socket.on("api-notifications", handleApiNotifications);

    // Initial connection attempt
    if (instance?.userData?.apiKey) {
      socket.auth = {
        token: instance.userData.apiKey,
        instanceName: instance.name,
      };
      socket.connect();
    }

    // Cleanup function
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", onConnectError);
      socket.off("api-notifications", handleApiNotifications);
      socket.disconnect();
    };
  }, [instance?.userData?.apiKey]);

  const handleRoleUpdate = useCallback(
    (data) => {
      try {
        // Se actualizó info de un rol.

        const { roleData } = data || {};

        const updatedRole = (inst) => {
          const condicion =
            inst?.userData?.roleInfo?._id &&
            inst?.userData?.roleInfo?._id === roleData?._id;

          return condicion;
        };

        if (roleData) {
          // Si en los workspaces tengo el que fue afectado lo actualizo.
          // Si no evito actualizar
          if (workspaces?.instances?.some((inst) => updatedRole(inst))) {
            setWorkspaces((prevState) => {
              const { instances } = prevState || {};

              if (instances?.length) {
                const updatedInstances = instances.map((inst) => {
                  if (updatedRole(inst)) {
                    return {
                      ...inst,
                      roleInfo: { ...inst.roleInfo, ...roleData },
                    };
                  }

                  return inst;
                });

                return { ...prevState, instances: updatedInstances };
              }

              return prevState;
            });

            // Checkeo que sea el usuario actual en la instancia actual
            if (updatedRole(instance)) {
              console.log("permisos actualizados");
              setInstance((prevState) => {
                const data = { ...prevState };

                data.userData.roleInfo = roleData;

                return data;
              });
            }
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    [instance, workspaces, setInstance, setWorkspaces]
  );

  const handleUserRoleUpdate = useCallback(
    (data) => {
      try {
        // Se modificó el rol asignado a un usuario

        const { userId, instanceId, roleData } = data || {};

        const updatedAssociation = (inst) => {
          const condicion =
            inst?.userData?.userId &&
            inst.userData.userId === userId &&
            inst._id === instanceId;

          return condicion;
        };

        if (roleData && userId) {
          // Si en los workspaces tengo el que fue afectado lo actualizo.
          // Si no evito actualizar
          if (workspaces?.instances?.some((inst) => updatedAssociation(inst))) {
            setWorkspaces((prevState) => {
              const { instances } = prevState || {};

              if (instances?.length) {
                const updatedInstances = instances.map((inst) => {
                  if (updatedAssociation(inst)) {
                    return {
                      ...inst,
                      roleInfo: { ...inst.roleInfo, ...roleData },
                    };
                  }

                  return inst;
                });

                return { ...prevState, instances: updatedInstances };
              }

              return prevState;
            });

            // Checkeo que sea el usuario en la instancia actual.
            if (updatedAssociation(instance)) {
              console.log("role actualizado");
              setInstance((prevState) => {
                const data = { ...prevState };

                data.userData.roleInfo = roleData;

                return data;
              });
            }
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    [instance, workspaces, setInstance, setWorkspaces]
  );

  return { isConnected, socket: socketRef.current };
};
