import { contactsService } from "../../../../helpers/fetchData/fetchContacts";
import { deleteLead } from "../../../../helpers/fetchData/fetchContactsLeads";
import { fetchAssociateClients } from "./fetchAssociateClients";

export const handleSaveAdd = async (
  formattedValues,
  setContactos,
  selectedClients,
  item,
  setLeads,
  setLoading,
  handleClose,
  leadMode,
  instance,
  messageApi,
  mainClient
) => {
  if (mainClient) {
    // Valido que el contacto principal sea una de las opciones seleccionadas
    if (!selectedClients?.includes(mainClient)) {
      setLoading(false);
      return messageApi.info(
        "La cuenta principal no se encuentra dentro de la lista de cuentas seleccionadas"
      );
    }
  }
  // 1. Añade el contacto:
  contactsService
    .create(instance?.userData?.apiKey, formattedValues)
    .then((res) => {
      if (res.data?._id) {
        messageApi.success("Contacto creado correctamente");

        // 2. Añade el contacto a la tabla:
        setContactos((prevState) => {
          let newArray = [...prevState];

          const nuevoContacto = {
            _id: res.data?._id,
            ...formattedValues,
            selectedClients,
            mainClient: mainClient || null,
          };

          newArray.unshift(nuevoContacto);

          return newArray;
        });

        const associateClientsData = {
          contactId: res.data?._id,
          clientsIds: selectedClients,
          mainClient: mainClient,
        };

        // 3. Asocia al contacto con los clientes indicados en el formulario:
        fetchAssociateClients(instance, associateClientsData, messageApi);

        // Si hay data de lead, actualizar / eliminar el lead:
        if (leadMode && item?._id) {
          deleteLead(instance?.userData?.apiKey, item._id)
            .then((res) => {
              if (res?.status === 200) {
                setLeads((prevState) => {
                  let array = [...prevState];

                  array = array.filter((element) => element._id !== item._id);

                  return array;
                });
              }
            })
            .catch((error) => {
              console.log("ERROR", error);
            })
            .finally(() => handleClose());
        } else {
          handleClose();
        }
      } else {
        messageApi.error("Ocurrió un error al crear el contacto");
      }
    })
    .catch((err) => {
      console.error(err);
      if (err?.response?.data?.message) {
        messageApi.error(err.response.data.message);
      } else {
        messageApi.error("Ocurrió un error al crear el contacto");
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
