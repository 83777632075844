import axios from 'axios';
import { API_URL } from '../../config/ENV_CONSTS';

export const newCampaign = async (instance, data) => {
  try {
    data.createdBy = instance?.userData?.userId;

    let config = {
      method: 'post',
      url: `${API_URL}/campaign/newCampaign`,
      headers: {
        Authorization: instance?.userData?.apiKey,
        'Content-Type': 'application/json'
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getCampaigns = async (key, data) => {
  try {
    let config = {
      method: 'get',
      url: `${API_URL}/campaign/getCampaigns`,
      headers: {
        Authorization: key,
        'Content-Type': 'application/json'
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

// export const getInnerMetrics = async (key, id) => {
//   try {
//     let config = {
//       method: 'get',
//       url: `${API_URL}/campaign/innerMetric/${id}`,
//       headers: {
//         Authorization: key,
//         'Content-Type': 'application/json'
//       }
//     };

//     const res = axios(config);

//     return await res;
//   } catch (error) {
//     console.log(error);
//   }
// };

export const updateCampaign = async (key, id, data) => {
  try {
    let config = {
      method: 'put',
      url: `${API_URL}/campaign/update/${id}`,
      headers: {
        Authorization: key,
        'Content-Type': 'application/json'
      },
      data: data
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCampaign = async (key, id) => {
  try {
    let config = {
      method: 'delete',
      url: `${API_URL}/campaign/delete/${id}`,
      headers: {
        Authorization: key,
        'Content-Type': 'application/json'
      }
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

/* export const getCampaignsMetrics = async (ids, apiKey) => {
  try {
    let config = {
      method: 'post',
      url: `${API_URL}/campaign/campaignsMetrics`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: apiKey
      },
      data: {
        ids
      }
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
}; */

export const getManyCampaignsMetrics = async (instance, ids) => {
  try {
    const config = {
      method: 'post',
      url: `${API_URL}/campaign/getManyCampaignsMetrics`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: instance?.userData?.apiKey
      },
      data: {
        ids
      }
    };

    const res = axios(config);

    return await res;
  } catch (error) {
    console.log(error);
  }
};

export const getCampaignData = async (instance, id) => {
  try {
    const config = {
      method: 'get',
      url: `${API_URL}/campaign/getCampaignData/${id}`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    const res = axios(config);

    return await res;
  } catch (err) {
    console.error(err);
  }
};

export const getRecipientsData = async (instance, campaignId) => {
  try {
    const config = {
      method: 'get',
      url: `${API_URL}/campaign/getRecipientsData/${campaignId}`,
      headers: {
        Authorization: instance?.userData?.apiKey
      }
    };

    const res = axios(config);

    return await res;
  } catch (err) {
    console.error(err);
  }
};

export const duplicateCampaign = async (instance, data) => {
  data.createdBy = instance?.userData?.userId;

  let config = {
    method: 'post',
    url: `${API_URL}/campaign/duplicate/`,
    headers: {
      Authorization: instance?.userData?.apiKey
    },
    data: data
  };

  const res = axios(config);

  return await res;
};

export const updateStatus = async (apiKey, id, data) => {
  let config = {
    method: 'patch',
    url: `${API_URL}/campaign/updateStatus/${id}`,
    headers: {
      Authorization: apiKey
    },
    data: data
  };

  const res = axios(config);

  return await res;
};

export const getCampaignLogs = async (apiKey, id) => {
  let config = {
    method: 'get',
    url: `${API_URL}/campaign/logs/${id}`,
    headers: {
      Authorization: apiKey
    }
  };

  const res = axios(config);

  return await res;
};

export const deleteCampaignLog = async (apiKey, id) => {
  let config = {
    method: 'delete',
    url: `${API_URL}/campaign/logs/${id}`,
    headers: {
      Authorization: apiKey
    }
  };

  const res = axios(config);

  return await res;
};

export const deleteAllCampaignLogs = async (apiKey, id) => {
  let config = {
    method: 'delete',
    url: `${API_URL}/campaign/allLogs/${id}`,
    headers: {
      Authorization: apiKey
    }
  };

  const res = axios(config);

  return await res;
};

export const exportCampaignDataToExcel = async (
  instance,
  campaignData,
  recipientsData
) => {
  const config = {
    method: 'post',
    url: `${API_URL}/campaign/exportCampaignDataToExcel`,
    headers: {
      Authorization: instance?.userData?.apiKey
    },
    responseType: 'blob',
    data: { campaignData, recipientsData }
  };

  const res = await axios(config);

  return res;
};

export const configCampaign = async (instance, data) => {
  const config = {
    method: 'patch',
    url: `${API_URL}/campaign/configCampaign`,
    headers: {
      Authorization: instance?.userData?.apiKey
    },
    data
  };

  const res = await axios(config);

  return res;
};
