import { useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccess } from "../../hooks/useAccess";
import { lastLoginUpdate } from "../../helpers/fetchData/fetchInstance";

const PrivateRoute = ({ children, accessData }) => {
  const { isAuthenticated, isLoading, logout, error } = useAuth0();
  const { loadingInstance, workspaces, setInstance, instance } =
    useContext(GlobalContext);

  const location = useLocation();
  const navigate = useNavigate();

  const isAllowed = useAccess();

  useEffect(() => {
    if (error) {
      console.log("Auth 0 error", error);
      // logout({ returnTo: window.location.origin });
    }
  }, [error]);

  const isValidWorkspace = () => {
    if (!instance?._id) {
      const selectedInstance = localStorage.getItem("workspace");

      if (selectedInstance) {
        let object = workspaces?.instances?.find(
          (element) => element._id === selectedInstance
        );

        if (object?._id) {
          setInstance(object);

          return;
        }
      }

      navigate("/main");
    }
  };

  useEffect(() => {
    if (instance?._id) {
      if (accessData) {
        if (!isAllowed(accessData, instance)) {
          navigate(accessData.redirect || "/dashboard");
        }
      }
    }
  }, [instance, location]);

  useEffect(() => {
    if (instance?.userData?.apiKey) {
      lastLoginUpdate(instance?.userData?.apiKey)
        .then((res) => {})
        .catch((err) => console.log(err));
    }
  }, [instance]);

  useEffect(() => {
    // Checkear si está authenticado y/o tiene una instancia válida.
    if (!isLoading) {
      if (!isAuthenticated) {
        return logout({ returnTo: window.location.origin });
        // navigate("/");
      }

      if (!loadingInstance) {
        isValidWorkspace();
      }
    }
  }, [isLoading, loadingInstance, workspaces]);

  // useEffect(() => {
  //   // let token = localStorage.getItem("token");
  //   // if (!token) logout({ returnTo: window.location.origin });
  // }, []);

  return children;
};

export default PrivateRoute;
