import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingIcon = ({
  size = 'default',
  color = '#77BE42',
  centered = true,
  chat = false
}) => {
  let iconSize;
  switch (size) {
    case 'mainLoader':
      iconSize = 64;
      break;
    case 'small':
      iconSize = 12;
      break;
    case 'large':
      iconSize = 24;
      break;
    case 'default':
    default:
      iconSize = 18;
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: iconSize, color: color }} spin />
  );

  if (centered) {
    return (
      <div
        style={{
          display: 'grid',
          height: '100%',
          width: '100%',
          placeItems: 'center',
          minHeight: chat ? 'auto' : 100,
        }}
      >
        <Spin indicator={antIcon} />
      </div>
    );
  }

  return <Spin indicator={antIcon} />;
};

export default LoadingIcon;
