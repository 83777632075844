import axios from "axios";
import { API_URL } from "../config/ENV_CONSTS";

const defaultHeaders = (apiKey) => ({
  Authorization: apiKey,
  "Content-Type": "application/json",
});

export const createAPIService = (endpoint) => {
  const URL_BASE = API_URL + endpoint;

  return {
    getAll: async (apiKey) => {
      const res = await axios({
        method: "get",
        url: URL_BASE,
        headers: defaultHeaders(apiKey),
      });
      return res;
    },

    getById: async (apiKey, id) => {
      const res = await axios({
        method: "get",
        url: `${URL_BASE}/${id}`,
        headers: defaultHeaders(apiKey),
      });
      return res;
    },

    create: async (apiKey, data) => {
      const res = await axios({
        method: "post",
        url: URL_BASE,
        headers: defaultHeaders(apiKey),
        data,
      });
      return res;
    },

    update: async (apiKey, id, data) => {
      const res = await axios({
        method: "put",
        url: `${URL_BASE}/${id}`,
        headers: defaultHeaders(apiKey),
        data,
      });
      return res;
    },

    delete: async (apiKey, id) => {
      const res = await axios({
        method: "delete",
        url: `${URL_BASE}/${id}`,
        headers: defaultHeaders(apiKey),
      });
      return res;
    },
  };
};
