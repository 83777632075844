import { Popover } from "antd";
import { FiInfo } from "react-icons/fi";

const SIZE = 14;

export const BoxInfo = ({
  message,
  hideMode = false,
  size = SIZE,
  placement = "top",
  color = "#000000E0",
  maxWidth = 600,
}) => {
  // Acepta 1 string o array de strings

  const renderContent = () => {
    if (Array.isArray(message)) {
      return (
        <span className="box-info" style={{ color: color, maxWidth }}>
          <span style={{ marginTop: 2, height: size }}>
            <FiInfo size={size} />
          </span>
          <span className="box-info-array-wrapper">
            {message.map((string, index) => {
              return <p key={index}>{string}</p>;
            })}
          </span>
        </span>
      );
    }

    return (
      <span className="box-info" style={{ color: color, maxWidth }}>
        <span style={{ marginTop: 2, height: size }}>
          <FiInfo size={size} />
        </span>
        <span>
          <p>{message}</p>
        </span>
      </span>
    );
  };

  if (hideMode) {
    return (
      <Popover content={renderContent} placement={placement}>
        <span
          style={{
            height: size,
            display: "grid",
            placeItems: "center",
            color: color,
          }}
        >
          <FiInfo size={size} />
        </span>
      </Popover>
    );
  } else {
    return renderContent();
  }
};
